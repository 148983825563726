import OutputHelper from './OutputHelper';

export default {
  // Stauchung
  getEffectUprightFactor(a, b, power) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const powerCasted = OutputHelper.convertToNumber(power);
    return powerCasted / (aCasted * bCasted);
  },
  getTransverseTractionA(a, b, t, power, effectUprightFactor) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const tCasted = OutputHelper.convertToInteger(t);
    const powerCasted = OutputHelper.convertToNumber(power);
    const effectUprightFactorCasted = OutputHelper.convertToNumber(effectUprightFactor);
    const value1 = 1.5 * aCasted * tCasted * effectUprightFactorCasted * (10 ** -3);
    const value2 = 1.5 * powerCasted * bCasted * tCasted * (10 ** -5);

    return Math.max(value1, value2);
  },
  getTransverseTractionB(a, b, t, power, effectUprightFactor) {
    const aCasted = OutputHelper.convertToInteger(a);
    const bCasted = OutputHelper.convertToInteger(b);
    const tCasted = OutputHelper.convertToInteger(t);
    const powerCasted = OutputHelper.convertToNumber(power);
    const effectUprightFactorCasted = OutputHelper.convertToNumber(effectUprightFactor);
    const value1 = 1.5 * bCasted * tCasted * effectUprightFactorCasted * (10 ** -3);
    const value2 = 1.5 * powerCasted * aCasted * tCasted * (10 ** -5);

    return Math.max(value1, value2);
  },
  getReinforcementToB(transverseTractionA, steelStretchMax) {
    const transverseTractionACasted = OutputHelper.convertToNumber(transverseTractionA);
    const steelStretchMaxCasted = OutputHelper.convertToInteger(steelStretchMax);
    return transverseTractionACasted * 11.5 / steelStretchMaxCasted;
  },
  getReinforcementToA(transverseTractionB, steelStretchMax) {
    const transverseTractionACasted = OutputHelper.convertToNumber(transverseTractionB);
    const steelStretchMaxCasted = OutputHelper.convertToInteger(steelStretchMax);
    return transverseTractionACasted * 11.5 / steelStretchMaxCasted;
  },
  getAmountRods(reinforcementToA, reinforcementToB) {
    const diameterOptions = [6, 8, 10, 12];
    const amountRods = [];
    diameterOptions.forEach((item) => {
      let aValue = 0;
      let bValue = 0;
      if (item === 6) {
        aValue = (reinforcementToA / 0.28);
        bValue = (reinforcementToA / 0.28);
      } else if (item === 8) {
        aValue = (reinforcementToB / 0.5);
        bValue = (reinforcementToA / 0.5);
      } else if (item === 10) {
        aValue = (reinforcementToB / 0.79);
        bValue = (reinforcementToA / 0.79);
      } else if (item === 12) {
        aValue = (reinforcementToB / 1.13);
        bValue = (reinforcementToA / 1.13);
      }
      amountRods.push({ a: aValue, b: bValue });
    });
    return amountRods;
  },
};
