<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="panel has-bg">
        <h3>{{ $t('transverseTraction.headlineBearingDimension') }}</h3>
        <div class="row">
          <!-- Lagerbeite -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingWidth"
            >
              <template slot="label">
                {{ $t('transverseTraction.bearingWidth') }} <span class="text-muted">a</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingWidth"
                  v-model="bearingWidth"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Lagerlänge -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingLength"
            >
              <template slot="label">
                {{ $t('transverseTraction.bearingLength') }} <span class="text-muted">b</span>
              </template>
              <b-input-group append="mm">
                <FormInputInteger
                  id="bearingLength"
                  v-model="bearingLength"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Lagerdicke -->
          <div class="col-lg-4">
            <b-form-group
              label-for="bearingThickness"
            >
              <template slot="label">
                {{ $t('transverseTraction.bearingThickness') }} <span class="text-muted">t</span>
              </template>
              <b-input-group>
                <b-form-select
                  id="bearingThickness"
                  v-model="bearingThickness"
                  :options="bearingThicknessOptions"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('transverseTraction.headlineEffects') }}</h3>
        <div class="row">
          <div class="col-lg-6">
            <!-- einwirkende Kraft -->
            <b-form-group
              label-for="maxEffectUpright"
            >
              <template slot="label">
                {{ $t('transverseTraction.maxEffectUpright') }}
                <span class="text-muted">F<sub>z,max,d</sub></span>
                <b-button
                  id="popover-power"
                  variant="link"
                >
                  <font-awesome-icon icon="question-circle" />
                </b-button>
                <b-popover
                  target="popover-power"
                  triggers="hover"
                  placement="top"
                >
                  <p>{{ $t('transverseTraction.maxEffectUprightTooltip') }}</p>
                  <p v-html="$t('general.tooltipAdditionalInfo')"></p>
                </b-popover>
              </template>
              <b-input-group append="kN">
                <FormInputInteger
                  id="power"
                  v-model="power"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="panel has-bg">
        <h3>{{ $t('transverseTraction.headlineIllustration') }}</h3>
        <img
          src="../../assets/img/abbildungen/querzugkraft.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="col-lg-6">
      <div class="panel">
        <h3>{{ $t('bearings.headlineKeyFigures') }}</h3>
        <div class="table-responsive">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>
                  {{ $t('transverseTraction.effectUprightFactor') }}
                  <span class="text-muted">E<sub>&perp;d</sub></span>
                  <b-button
                    id="popover-effect-upright-factor"
                    variant="link"
                  >
                    <font-awesome-icon icon="question-circle" />
                  </b-button>
                  <b-popover
                    target="popover-effect-upright-factor"
                    triggers="hover"
                    placement="top"
                  >
                    <p>{{ $t('transverseTraction.effectUprightFactorTooltip') }}</p>
                    <p v-html="$t('general.tooltipAdditionalInfo')"></p>
                  </b-popover>
                </td>
                <td class="text-right">
                  {{ effectUprightFactor | numeralFormat('0.0') }} N/mm²
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('transverseTraction.transverseTractionEffectA') }}
                  <span class="text-muted">Z<sub>a</sub></span>
                </td>
                <td class="text-right">
                  {{ transverseTractionEffectA | numeralFormat('0.00') }} kN
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('transverseTraction.transverseTractionEffectB') }}
                  <span class="text-muted">Z<sub>b</sub></span>
                </td>
                <td class="text-right">
                  {{ transverseTractionEffectB | numeralFormat('0.00') }} kN
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('transverseTraction.reinforcementToB') }}
                  <span class="text-muted">A<sub>s1</sub></span>
                </td>
                <td class="text-right">
                  {{ reinforcementToB | numeralFormat('0.00') }} cm²
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('transverseTraction.reinforcementToA') }}
                  <span class="text-muted">A<sub>s2</sub></span>
                </td>
                <td class="text-right">
                  {{ reinforcementToA | numeralFormat('0.00') }} cm²
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t('transverseTraction.steelStretchMax') }}
                  <span class="text-muted">f<sub>yk</sub></span>
                  <b-button
                    id="popover-steel-stretch-max"
                    variant="link"
                  >
                    <font-awesome-icon icon="question-circle" />
                  </b-button>
                  <b-popover
                    target="popover-steel-stretch-max"
                    triggers="hover"
                    placement="top"
                  >
                    <p>{{ $t('transverseTraction.steelStretchMaxTooltip') }}</p>
                    <p v-html="$t('general.tooltipAdditionalInfo')"></p>
                  </b-popover>
                </td>
                <td class="text-right">
                  {{ steelStretchMax | numeralFormat('0') }} MN/m²
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="panel">
        <h3>{{ $t('transverseTraction.headlineAmountRoads') }}</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>{{ $t('transverseTraction.rodDiameter') }}</th>
              <th class="text-center">
                6 mm
              </th>
              <th class="text-center">
                8 mm
              </th>
              <th class="text-center">
                10 mm
              </th>
              <th class="text-center">
                12 mm
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ $t('transverseTraction.amountRods') }} &perp;a
              </td>
              <td
                v-for="(item, index) in amountRods"
                :key="index"
                class="text-center"
              >
                {{ item.a | numeralFormat('0') }}
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('transverseTraction.amountRods') }} &perp;b
              </td>
              <td
                v-for="(item, index) in amountRods"
                :key="index"
                class="text-center"
              >
                {{ item.b | numeralFormat('0') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputInteger from '../app/form/FormInputInteger.vue';
import DisabledInputs from '../../mixins/DisabledInputs';
import CalcToolsTransverseTraction from '../../helpers/CalcToolsTransverseTractionHelper';

export default {
  components: {
    FormInputInteger,
  },
  mixins: [DisabledInputs],
  props: {
    propBearingWidth: {
      type: Number,
      default: 100,
    },
    propBearingLength: {
      type: Number,
      default: 150,
    },
    propBearingThickness: {
      type: Number,
      default: 20,
    },
    propPower: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      bearingThicknessOptions: Object.values(this.$dataOptionsHelper.getBearingThicknessOptions()),
      steelStretchMax: 435,
      bearingWidth: this.propBearingWidth,
      bearingLength: this.propBearingLength,
      bearingThickness: this.propBearingThickness,
      power: this.propPower,
    };
  },
  computed: {
    amountRods() {
      return CalcToolsTransverseTraction.getAmountRods(
        this.reinforcementToA,
        this.reinforcementToB,
      );
    },
    effectUprightFactor() {
      return CalcToolsTransverseTraction.getEffectUprightFactor(
        this.bearingWidth,
        this.bearingLength,
        this.$outputHelper.convertToInteger(this.power) * 1000,
      );
    },
    transverseTractionEffectA() {
      return CalcToolsTransverseTraction.getTransverseTractionA(
        this.bearingWidth,
        this.bearingLength,
        this.bearingThickness,
        this.power,
        this.effectUprightFactor,
      );
    },
    transverseTractionEffectB() {
      return CalcToolsTransverseTraction.getTransverseTractionB(
        this.bearingWidth,
        this.bearingLength,
        this.bearingThickness,
        this.power,
        this.effectUprightFactor,
      );
    },
    reinforcementToA() {
      return CalcToolsTransverseTraction.getReinforcementToA(
        this.transverseTractionEffectB,
        this.steelStretchMax,
      );
    },
    reinforcementToB() {
      return CalcToolsTransverseTraction.getReinforcementToA(
        this.transverseTractionEffectA,
        this.steelStretchMax,
      );
    },
  },
};
</script>

<style scoped lang="scss">

</style>
