<template>
  <ToolsFormTransverseTraction />
</template>

<script>
import ToolsFormTransverseTraction from '../../components/tools/ToolsFormTransverseTraction.vue';

export default {
  components: {
    ToolsFormTransverseTraction,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
